import cryptoJs from 'crypto-js';
const key = 'ycyh@abcd';
export function decryptDes(message) {
    const keyHex = cryptoJs.enc.Utf8.parse(key)
    const decrypted = cryptoJs.DES.decrypt(
        {
            ciphertext: cryptoJs.enc.Hex.parse(message)
        },// 若message是base64格式，则无需转16进制hex，直接传入message即可
        keyHex,
        {
            mode: cryptoJs.mode.ECB,
            padding: cryptoJs.pad.Pkcs7
        }
    )
    return decrypted.toString(cryptoJs.enc.Utf8)
}

export function getQueryString(name) {
    let reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i');
    let search = window.location.href.split('?')[1];
    let queryInfo = [];
    let query = '';
    if (search.indexOf('&') != -1) {
        queryInfo = search.split('&');
        queryInfo[0] = decryptDes(queryInfo[0]);
    }else{
        query = decryptDes(search);
    }
    if (!query){
        query = queryInfo.join('&')
    }
    let message = query.match(reg);
    if (message !== null) {
        return unescape(message[2]);
    }
    return null
}
