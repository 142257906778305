import request from './axios';
// 查勘员相关
export function sms_post(data) {
    return request({
        url: '/surveyor',
        method: 'post',
        data: data
    })
}
// app下载
export function appDownload(data) {
    return request({
        url: '/appDownload',
        method: 'post',
        data: data
    })
}
